import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/slowniczek/" className="sel">
              {" "}
              &gt; Słowniczek
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <h1>Słowniczek</h1>
              <nav id="vocu_nav">
                <a className="active" href="#A">
                  {" "}
                  A
                </a>
                <a className="active" href="#B">
                  {" "}
                  B
                </a>
                <a> C</a>
                <a className="active" href="#D">
                  {" "}
                  D
                </a>
                <a> E</a>
                <a> F</a>
                <a className="active" href="#G">
                  {" "}
                  G
                </a>
                <a className="active" href="#H">
                  {" "}
                  H
                </a>
                <a className="active" href="#I">
                  {" "}
                  I
                </a>
                <a> J</a>
                <a className="active" href="#K">
                  {" "}
                  K
                </a>
                <a> L</a>
                <a> Ł</a>
                <a> M</a>
                <a className="active" href="#N">
                  {" "}
                  N
                </a>
                <a className="active" href="#O">
                  {" "}
                  O
                </a>
                <a className="active" href="#P">
                  {" "}
                  P
                </a>
                <a> R</a>
                <a className="active" href="#S">
                  {" "}
                  S
                </a>
                <a> Ś</a>
                <a className="active" href="#T">
                  {" "}
                  T
                </a>
                <a className="active" href="#U">
                  {" "}
                  U
                </a>
                <a className="active" href="#W">
                  {" "}
                  W
                </a>
                <a> Y</a>
                <a className="active" href="#Z">
                  {" "}
                  Z
                </a>
                <a> Ż</a>
                <a> Ź</a>
              </nav>
              <span id="A" />
              <div className="row" id="alergen">
                <p className="title">Alergen</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        antygen zdolny do wyzwalania swoistej nadwrażliwości
                        organizmu (reakcji alergicznej) u osób o osobniczej
                        predyspozycji (skłonności) do alergii. Alergeny
                        posiadają zdolność pobudzania organizmu do produkcji
                        swoistych przeciwciał IgE lub komórek swoiście
                        reagujących na określony alergen.&nbsp; W przypadku
                        kontaktu osoby uczulonej z alergenem, dochodzi u niej do
                        wyzwolenia alergicznej reakcji zapalnej, która może
                        przybierać różne formy w zależności od zajętego narządu
                        (np. astma, alergiczny nieżyt nosa czyli tzw. katar
                        sienny, alergiczne kontaktowe zapalenie skóry, wyprysk
                        atopowy i inne). Alergeny podzielić można ze względu na
                        główną drogę penetracji na wziewne (powietrzno pochodne)
                        i pokarmowe, lub też ze względu na okres ekspozycji na
                        sezonowe (pyłek roślin, grzyby pleśniowe) i całoroczne
                        (roztocze kurzu domowego, alergeny zwierząt domowych).
                        Pomimo zmiany nazewnictwa alergicznego nieżytu nosa na
                        okresowy i przewlekły, nazewnictwo alergenów z podziałem
                        na sezonowe i całoroczne pozostało bez zmian.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="alergia">
                <p className="title">Alergia</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        nabyta, nadmierna i niewłaściwa reakcja układu
                        immunologicznego wywołana ekspozycją na alergeny, które
                        wywołują objawy chorobowe u osób do tego
                        predysponowanych. Przykładowymi objawami mogą być:
                        katar, obfita wydzielina z nosa (wodnista lub
                        wodnisto-śluzowa), świąd nosa, zatkanie nosa (jedno lub
                        obustronne), świąd oczu oraz drapanie w gardle.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="alergiczny_niezyt_nosa">
                <p className="title">Alergiczny Nieżyt Nosa </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      stan zapalny błony śluzowej nosa i zatok powstały w wyniku
                      kontaktu błony śluzowej z określonym alergenem wziewnymi.
                      Alergiczny nieżyt nosa objawia się świądem nosa,
                      kichaniem, wyciekiem wodnistej wydzieliny z nosa i
                      uczuciem zatkania nosa. Mogą też występować dodatkowe
                      objawy, takie jak ból głowy, upośledzenie węchu i objawy
                      spojówkowe. Alergiczny nieżyt nosa można podzielić na
                      całoroczny, sezonowy i zawodowy, w zależności od czasu
                      ekspozycji na czynnik wywołujący. Całoroczny alergiczny
                      nieżyt nosa najczęściej jest wywołany przez roztocze kurzu
                      domowego i alergeny naskórkowe zwierząt. Sezonowy
                      alergiczny nieżyt nosa powoduje wiele różnych pyłków
                      roślin, w tym traw, roślin z rodziny pokrzywowatych,
                      komosy, bylicy, oliwki, leszczyny i cyprysu. Występowanie
                      sezonowego alergicznego nieżytu nosa zależy oczywiście od
                      obszaru geograficznego, sezonu pylenia roślin i
                      miejscowego klimatu.
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="antygen">
                <p className="title">Antygen</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        substancja, którą ustrój rozpoznaje jako obcą i która z
                        tej przyczyny zdolna jest do wywołania w ustroju reakcji
                        immunologicznej.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="B" />
              <div className="row" id="blona_sluzowa_nosa">
                <p className="title">Błona śluzowa nosa (śluzówka)</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        wyściółka jamy nosa. Pełni ona funkcję ochronną,
                        nawilżającą i oczyszczającą. Powierzchnia błony śluzowej
                        nosa jest wyścielona przez komórki nabłonka
                        i&nbsp;pokryta śluzem.&nbsp; Gruczoły śluzowe wytwarzają
                        duże ilości wydzieliny śluzowej, która nawilża nabłonek,
                        a rzęski nabłonka oddechowego unieruchamiają
                        zanieczyszczenia (np.&nbsp;kurz, bakterie). Błona
                        śluzowa może zostać uszkodzona w wyniku działania
                        alergenów, bakterii, wirusów (infekcji górnych dróg
                        oddechowych), a także niektórych leków.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="D" />
              <div className="row" id="dekstrometorfan">
                <p className="title">Dekstrometorfan</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        substancja wykazująca silne właściwości przeciwkaszlowe.
                        Zalicza się ją do grupy leków tzw. pochodnych
                        opioidowych. Mechanizm działania polega na wywieraniu
                        hamującego wpływu na ośrodek kaszlowy znajdujący się w
                        rdzeniu przedłużonym Przeciwkaszlowe działanie
                        dekstrometorfanu, podanego doustnie w dawce od 10 do 20
                        mg, występuje w ciągu 1 godziny i utrzymuje się przez co
                        najmniej 4 godziny po podaniu leku.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="G" />
              <div className="row" id="glicerol">
                <p className="title">Glicerol</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        bezbarwna, substancja o słodkim smaku zaliczana do grupy
                        alkoholi wielowodorotlenowych (polioli). Ze względu na
                        nagromadzenie w swojej strukturze grup hydroksylowych
                        wykazuje właściwości higroskopijne, które wykorzystywane
                        są m.in. w przemyśle farmaceutycznym i kosmetycznym.
                        Jest także stosowany jako substancja słodząca m.in. w
                        syropach przeciwkaszlowych.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="grypa">
                <p className="title">Grypa</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        zespół objawów klinicznych związanych z&nbsp;ostrym
                        zakażeniem układu oddechowego, wywołanym przez wirusa
                        grypy. Jej przebieg jest zależny od typu wirusa, który
                        ją wywołał. Najczęstsze objawy to: wysoka gorączka,
                        sięgająca nawet 39-40 stopni C, dreszcze i ból głowy,
                        ból kostno-stawowy, suchy kaszel, katar.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="H" />
              <div className="row" id="humektanty">
                <p className="title">Humektanty</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        silnie higroskopijne substancje, dobrze rozpuszczalne w
                        wodzie, które mają zdolność do trwałego wiązania i
                        zatrzymywania wody z otoczenia, dzięki czemu pełnią one
                        rolę naturalnego rezerwuaru wody w skórze.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="I" />
              <div className="row" id="immunoglobuliny">
                <p className="title">Immunoglobuliny</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        to inaczej przeciwciała, które odgrywają ważną rolę w
                        procesie ochrony organizmu przed bakteriami, wirusami i
                        drobnoustrojami. Posiadają zdolność wiązania i łączenia
                        przeciwciał i umożliwiają proces niszczenia zagrożenia
                        zewnątrzkomórkowego.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="infekcja">
                <p className="title">Infekcja</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        przedostanie się do organizmu drobnoustrojów
                        chorobotwórczych. Ze względu na przyczynę pojawienia się
                        zakażenia można wyróżnić infekcję wirusową (rozwijającą
                        się w wyniku przedostania się wirusów do organizmu wraz
                        z wdychanym powietrzem) i infekcję bakteryjną (wywołaną
                        zwykle paciorkowcami lub rzadziej gronkowcami).
                        &nbsp;&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="K" />
              <div className="row" id="ksylometazolina">
                <p className="title">Ksylometazolina</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        lek zmniejszający przekrwienie błony śluzowej nosa,
                        stosowany donosowo w przebiegu niealergicznego i
                        alergicznego nieżytu nosa. Działanie obkurczające błonę
                        śluzową nosa utrzymuje się od 10 do 12 godzin Wybierając
                        odpowiednią dawkę można zastosować ją zarówno u
                        dorosłych, jak i dzieci. Podawana jest donosowo,
                        maksymalnie do 3 razy na dobę do każdego otworu
                        nosowego.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="kwas_hialuronowy">
                <p className="title">Kwas hialuronowy </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        substancja szeroko rozpowszechniona w przyrodzie.
                        Praktycznie występuje we wszystkich organizmach żywych,
                        w tym także w organizmie ludzkim. Szczególnie dużą jego
                        ilość można znaleźć w tkance łącznej, w ciałku szklistym
                        oka, pępowinie, mazidłach stawowych. Jego stężenie w
                        dużej mierze zależy od wieku i wraz ze starzeniem się
                        organizmu maleje. Kwas hialuronowy uważany jest za jeden
                        z najlepszych środków nawilżających i chroniących
                        naskórek przed wysychaniem. Surowiec ten jest bardzo
                        chętnie wykorzystywany w kosmetyce, farmacji, gdzie
                        stosuje się go zarówno w postaci wolnej, jak i soli
                        (hialuronianu sodowego).
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="N" />
              <div className="row" id="niezyt_nosa">
                <p className="title">Nieżyt nosa</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        jest objawową chorobą zapalną błony śluzowej wywołaną
                        najczęściej przez czynniki alergiczne, niealergiczne i
                        infekcyjne. Kliniczne określenie <i>nieżyt nosa</i>{" "}
                        odnosi się do schorzeń nosa charakteryzujących się dwoma
                        lub więcej z następujących objawów: kichanie, swędzenie
                        nosa, wyciek z nosa, zablokowanie (obrzęk) nosa.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="O" />
              <div className="row" id="obrzek_blony_sluzowej">
                <p className="title">
                  Obrzęk (przekrwienie) błony śluzowej nosa{" "}
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        jeden z objawów zapalenia błony śluzowej nosa, powoduje
                        niedrożność nosa, a co się z tym wiąże trudności w
                        oddychaniu i zaburzenia węchu.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="P" />
              <div className="row" id="patogen">
                <p className="title">Patogen</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        żywy lub nieożywiony czynnik (tzw. czynnik
                        chorobotwórczy) wywołujący chorobę ludzi, zwierząt lub
                        roślin. Do patogenów zaliczamy m.in drobnoustroje,
                        bakterie, wirusy, grzyby, pasożyty zwierzęce oraz
                        substancje chemiczne.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="produkt_leczniczy ">
                <p className="title">Produkt leczniczy (lek) </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        substancja lub mieszanina substancji (produkt leczniczy
                        złożony), przedstawiana jako posiadająca właściwości
                        zapobiegania bądź leczenia chorób występujących u ludzi
                        lub zwierząt, lub podawana w celu postawienia diagnozy,
                        lub w celu przywrócenia, poprawienia lub modyfikacji
                        fizjologicznych funkcji organizmu poprzez działanie
                        farmakologiczne, immunologiczne lub metaboliczne.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="przeziebienie">
                <p className="title">Przeziębienie</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        zespół objawów związanych z&nbsp;zapaleniem błony
                        śluzowej nosa, gardła i&nbsp;zatok przynosowych,
                        spowodowany zakażeniem wirusowym. Najczęstsze wirusy
                        powodujące przeziębienie to: rinowirusy&nbsp;,
                        koronawirusy&nbsp;i inne&nbsp; (m.in. adenowirusy,
                        wirusy Coxsackie, wirusy paragrypy, parwowirusy, RSV
                        i&nbsp;enterowirusy). Choroba często rozpoczyna się
                        okresem złego samopoczucia. Niektórzy pacjenci skarżą
                        się w&nbsp;tym czasie na uczucie wysychania, pieczenia
                        lub drapania w&nbsp;nosie. Są to wczesne objawy
                        towarzyszące inwazji wirusów na nabłonek błony śluzowej
                        nosa. Kolejnym objawem jest często ból gardła, czasem
                        chrypka. Następnie pojawiają się katar i&nbsp;kichanie.
                        Ból gardła zwykle mija po 1—2 dniach. Początkowo
                        wodnisty katar z&nbsp;czasem staje się gęstszy
                        i&nbsp;często ma ropny charakter (żółte lub zielonkawe
                        zabarwienie). U niektórych chorych pojawia się również
                        kaszel. W&nbsp;pierwszych dniach choroby może występować
                        stan podgorączkowy (temperatura ciała 37–38°C). Gorączka
                        częściej pojawia się u&nbsp;dzieci.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="pseudoefedryna">
                <p className="title">Pseudoefedryna</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        substancja (pochodna efedryny) działająca na receptory α
                        i β2-adrenergiczne zlokalizowane w błonie śluzowej dróg
                        oddechowych. Podana doustnie, poprzez działanie
                        zwężające naczynia, zmniejsza przekrwienie błony
                        śluzowej nosa. Stosuje się ją (także w produktach
                        złożonych w połączeniu z innymi substancjami) w
                        objawowym leczeniu zapalenia błony śluzowej nosa i zatok
                        przynosowych (katar, zatkany nos) w przebiegu:
                        przeziębienia, grypy, alergicznego zapalenia błony
                        śluzowej nosa. W postaci tabletek może być stosowana u
                        dorosłych i dzieci w wieku powyżej 12 lat. Podawana jest
                        doustnie zwykle w dawce 60 mg co 4-6 godzin (do 4 dawek
                        na dobę), zazwyczaj do 7 dni, u dzieci maksymalnie przez
                        4 dni. Po podaniu doustnym działanie odblokowujące nos
                        pojawia się po 30 min i&nbsp;utrzymuje się od 3 do 4
                        godz.
                      </span>
                    </p>
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      &nbsp;
                    </p>
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      &nbsp;
                    </p>
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Źródło:
                    </p>
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      Sylwia Stachura, <i>Pseudoefedryna</i> [online], [dostęp:
                      27 kwiecień 2016], Dostępny w Internecie:{" "}
                      <a href="https://portal.abczdrowie.pl/pseudoefedryna">
                        https://portal.abczdrowie.pl/pseudoefedryna
                      </a>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="S" />
              <div className="row" id="sorbitol">
                <p className="title">Sorbitol</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        bezbarwna, substancja o słodkim smaku zaliczana do grupy
                        alkoholi wielowodorotlenowych (polioli). Podobnie jak
                        glicerol wykazuje właściwości higroskopijne, które
                        wykorzystywane są m.in. w przemyśle farmaceutycznym i
                        kosmetycznym. W farmacji sorbitol jest stosowany jako
                        substancja słodzącą zamiast cukru, glicerolu lub jako
                        substancja utrzymująca wilgoć, zagęszczająca,
                        zmiękczająca i wypełniająca oraz wiążąca na sucho lub w
                        postaci 2-25%owych roztworów.
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="T" />
              <div className="row" id="trabka_sluchowa">
                <p className="title">
                  Trąbka słuchowa (inaczej trąbka Eustachiusza){" "}
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        przewód chrzęstno-kostny, który łączy przestrzenie
                        powietrzne ucha środkowego ze światem zewnętrznym za
                        pośrednictwem części nosowej gardła i nosa. W przypadku
                        niewydolności trąbki słuchowej lub jej nieprawidłowym
                        funkcjonowaniem może występować zapalenie ucha
                        środkowego U dzieci trąbka Eustachiusza jest krótsza,
                        szersza i ma bardziej poziomy przebieg, niż u dorosłych.
                        Mniej więcej do 2. roku życia, ujście gardłowe jest
                        stale niedomknięte. U osoby dorosłej zaś ujście jest
                        stale zamknięte – otwiera się tylko podczas połykania,
                        kichania czy ziewania. Taka budowa trąbki Eustachiusza u
                        dziecka sprawia, że infekcje łatwiej przenikają z jamy
                        ustnej, gardła czy nosa poprzez trąbkę do ucha. Spełnia
                        ona bowiem rolę wentyla bezpieczeństwa, oddzielającego
                        nosa i gardło, będące rezerwuarem drobnoustrojów
                        chorobotwórczych, od względnie sterylnego ucha
                        środkowego.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <div className="row" id="triprolidyna">
                <p className="title">Triprolidyna</p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        substancja (pochodna pyrolidyny), która w sposób
                        kompetencyjny i odwracalny blokuje receptory
                        histaminowe. Powoduje to zahamowanie działania histaminy
                        na receptor typu H1 i w rezultacie zmniejszenie objawów
                        alergii. Triprolidyna zmniejsza kichanie i świąd.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="U" />
              <div className="row" id="uklad_immunologiczny">
                <p className="title">Układ immunologiczny </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        inaczej nazywany układem odpornościowym lub
                        limfatycznym. Jego zadaniem jest ochrona organizmu przed
                        infekcjami, wirusami i bakteriami.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="W" />
              <div className="row" id="wydzielina_z_nosa">
                <p className="title">Wydzielina z nosa </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        śluz pochodzący z górnych dróg oddechowych (jamy
                        nosowej) wydzielany w nadmiernej ilości podczas kataru
                        (nieżytu nosa). Kolor i konsystencja wydzieliny wskazują
                        na&nbsp;rodzaj nieżytu nosa. Rzadka o jasnym kolorze
                        wydzielina to znak kataru wirusowego, zielonkawa bądź
                        żółta sygnalizuje zakażenie bakteriami, z kolei jasna i
                        bardzo wodnista oznacza katar alergiczny.&nbsp;
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
              <span id="Z" />
              <div className="row" id="zapalenia_blony_sluzowej_nosa">
                <p className="title">
                  Zapalenia błony śluzowej nosa i zatok przynosowych
                </p>
                <div className="desc">
                  <div className="wrapper">
                    <p
                      className="p1"
                      style={{
                        "-webkit-text-align": "justify",
                        "text-align": "justify"
                      }}
                    >
                      <span className="s1">
                        rozpoznajemy stwierdzając obecność dwóch lub więcej
                        spośród czterech objawów chorobowych: niedrożność nosa,
                        wyciek wydzieliny z nosa, ból lub rozpieranie w obrębie
                        twarzoczaszki, zaburzenia lub utrata węchu. Przy czym
                        jednym z objawów musi być „objaw duży”: niedrożność nosa
                        lub wyciek wydzieliny, niezależnie czy jest to katar
                        „przedni”, czy jest to uczucie zaciekania wydzieliny na
                        tylnej ścianie gardła (katar tylny zwany także
                        zanosowym).
                      </span>
                    </p>
                  </div>
                </div>
                <span className="to_open">rozwiń &gt;</span>
                <span className="to_close">zwiń &lt;</span>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem/">
                    <div className="c1">
                      <span className="photo" style={{}} />
                    </div>
                    <div className="c2">
                      Domowe sposoby na walkę z przeziębieniem{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/katar/poznaj_rodzaje_kataru/">
                    <div className="c1">
                      <span className="photo" style={{}} />
                    </div>
                    <div className="c2">Poznaj rodzaje kataru </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/kcfinder/uploads/files/box/film_mini.png" />
              </a>
              <a href="/slowniczek/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
